@import '../variables/color.css';
@custom-media --sm (min-width: 544px);
@custom-media --lg (min-width: 992px);

@define-mixin home $block: .home {
    $(block) {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_router {
            display: flex;
            flex-wrap: wrap;
            font-size: 15vw;

            @media (--sm) {
                font-size: 8vw;
            }
        }

        &_title {
            font-size: 18vw;
            margin: 0;
            text-align: center;
            font-weight: 100;
            color: white;
            flex-basis: 100%;
            padding-bottom: 8vh;

            @media (--sm) {
                font-size: 15vw;
            }

            @media (--lg) {
                font-size: 10vw;
            }
        }

        &_links {
            width: 80vw;
        }

        &_strip {
            transform: skew(-15deg);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            margin-bottom: 16px;
            overflow: hidden;


        }

        &_link {
            text-align: center;
            font-size: 48px;
            transform: skew(15deg);
            color: black;
            text-decoration: none;
            width: 100%;
            padding: 2vh 0;

            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 100%;
                left: -21px;
                top: 0;
                background: #949fb1;
                z-index: -1;
                transition: width .3s ease;
            }

            &:hover:before {
                width: 42px;
            }
        }
    }
}

@mixin home;
