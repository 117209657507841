 @custom-media --sm (min-width: 544px);
@import '../variables/color.css';

@define-mixin webdev $block: .webdev {
    $(block) {
        transition: transform .3s cubic-bezier(.86, 0, .07, 1);
        transform-origin: top left;
        box-shadow: 0 0 5px 1px black;
        z-index: 0;
        background-color: #f1f1f1;

        &_banner {
            height: 50vh;
            overflow: hidden;
            background-color: #949fb1;
        }

        &_video {
            opacity: .7;
            min-width: 100%;
        }

        &_strip {
            position: relative;
            background-color: white;
            transform: skewY(-3deg) translateY(-50px);
            filter: drop-shadow(0 0 0 black)
        }

        &_title {
            margin: 0;
            font-size: 20vw;
            font-weight: 100;
            text-align: center;
            transform: skewY(3deg);

            @media (--sm) {
                font-size: 10vw;
            }
        }

        &_skills {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            background-color: white;
        }

        &_col {
            display: flex;
            flex-direction: column;
            padding: 24px;
            flex: 1 0 auto;
            &-sub {
                padding: 0;
                margin-bottom: 12px;
            }
        }
    }
}

@mixin webdev;