@custom-media --sm (min-width: 544px);
@custom-media --md (min-width: 544px);
@custom-media --lg (min-width: 992px);

@define-mixin gallery $block: .gallery {
  $(block) {
    &_content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
      justify-content: center;
      position: relative;

      @media (--sm) {
        grid-template-columns: repeat(auto-fit, 250px);
      }
    }

    &_item {
      cursor: pointer;
      width: 100%;
      transition: filter .2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      &:hover {
        filter: opacity(90%);
      }
    }

    &_lightbox {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    &_fullscreen {
      position: fixed;
      top: 50%;
      left: 0;
      width: 100%;
      transform: scale(1) translateY(-50%);
      transform-origin: top center;
      z-index: 2;

      @media (--sm) {
        transform: scale(.8) translateY(-50%);
      }
    }

    &_btns {
      position: fixed;
      z-index: 2;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      top: 80%;
      transform: translateY(-50%);

      @media (--md) {
        top: 50%;
      }
    }

    &_close {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 2;
    }

    &_close,
    &_next,
    &_prev {
      border: none;
      border-radius: 2px;
      text-transform: uppercase;
      padding: 24px;
      cursor: pointer;
      color: black;
      background-color: rgba(255, 255, 255, .3);
      border: 1px solid black;
      text-transform: uppercase;
      letter-spacing: 2px;
      outline: none;
      transition: background 0.2s;

      &:hover {
        background-color: #3a3fe4;
        color: white;
      }
    }
  }
}

@mixin gallery;