@custom-media --sm (min-width: 544px);
@import '../variables/color.css';

@define-mixin artist $block: .artist {
    $(block) {
        transition: transform .3s cubic-bezier(.86, 0, .07, 1);
        transform-origin: top left;
        box-shadow: 0 0 5px 1px black;
        z-index: 0;
        background-color: #f1f1f1;

        &_banner {
            height: 50vh;
            background-image: url('../../images/blender/grapes.jpg');
            background-position: bottom;
            background-size: cover;

            &:before, &:after {
                content: '';
                background: var(--primaryBgColor);
                top: 0;
                width: 8px;
                height: 50vh;
                display: block;
                position: absolute;

                @media (--sm) {
                    width: 16px;
                }
            }

            &:before {
                left: 30%;
            }

            &:after {
                left: 70%;
            }
        }

        &_strip {
            position: relative;
            background-color: white;
            transform: skewY(-3deg) translateY(-50px);
            filter: drop-shadow(0 0 0 black)
        }

        &_title {
            margin: 0;
            font-size: 20vw;
            font-weight: 100;
            text-align: center;
            transform: skewY(3deg);

            @media (--sm) {
                font-size: 10vw;
            }
        }

        &_tabs {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 1600px;
            margin: 0 auto;
        }

        &_tab {
            font-size: 32px;
            margin: 8px 16px;
            text-decoration: none;
            color: black;
            text-align: center;
            flex-grow: 1;
            flex-basis: auto;
            font-weight: 100;
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            z-index: 0;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                left: 0;
                bottom: 0;
                transform: rotate(2deg) translateY(15px);
                background: #949fb1;
                opacity: 0;
                z-index: -1;
                transition: transform .3s ease, height .3s ease-in-out;
            }

             &:hover::before {
                opacity: 1;
                transform: rotate(2deg) translateY(5px);
            }
        }
    }
}

@mixin artist;
