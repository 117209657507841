@custom-media --md (min-width: 544px);

@define-mixin art-gallery $block: .art-gallery {
  $(block) {
    max-width: 1600px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: white;
    position: relative;
    padding: 8px;

    &_rotate-title {
      text-transform: uppercase;
      transform: rotate(90deg);
      width: 100px;
      transform-origin: bottom left;
      margin: 0;
      line-height: 1;
      font-size: 24px;
    }

    &_title {
      margin: 0 0 24px 24px;
      font-size: 80px;
      line-height: 1;
      font-weight: 300;

      &-sub {
        font-size: 48px;
        display: inline-block;
      }
    }

    &_desc {
      margin-bottom: 24px;
    }

    &_text {
      margin: 0 0 16px 0;

      &-highlight {
        font-weight: bold;
      }
    }

    @media (--md) {
      padding: 16px;
    }

    &_img {
      position: absolute;
      top: 16px;
      right: 16px;
      opacity: .2;
      width: 256px;
    }

    &_asset {
      margin-top: 24px;
    }

    &_link {
      margin: 0 0 24px 24px;
      display: inline-block;
    }
  }
}

@mixin art-gallery;
