@custom-media --sm (min-width: 544px);
@custom-media --lg (min-width: 992px);

@import '../variables/color.css';

@define-mixin sidebar $block: .sidebar {
    $(block) {
        &_relative {
            position: relative;
            height: 100%;
        }

        &_btn {
            appearance: none;
            border: 0;
            background: transparent;
            width: 40px;
            height: 40px;
            position: fixed;
            top: 0;
            z-index: 1;
            transition: left .3s ease, transform .3s ease;
        }

        &_icon {
            position: absolute;
            top: 0;
            opacity: 1;
            transition: opacity .3s cubic-bezier(.86, 0, .07, 1), transform .3s cubic-bezier(.86, 0, .07, 1);

            &-bars {
                transform: translateX(-50%);
                left: 50%;
            }

            &-close {
                transform: translateX(-50%);
                left: 50%;
            }

            &-open&-bars {
                transform: rotate(-10deg) translate(100%, -100%);
                opacity: 0;
            }

            &-open&-close {
                opacity: 0;
                transform: translateX(-100%);
            }
        }

        &_nav {
            transition: opacity .3s ease, transform .3s ease;
            height: 100%;
            background: white;
            padding: 64px 16px;
            margin: 0;
            position: fixed;
            z-index: 0;
            width: 100%;
            opacity: 0;
            list-style-type: none;
            pointer-events: none;
        }

        &_item {
            margin-bottom: 16px;
            overflow: hidden;
        }

        &_link {
            text-decoration: none;
            font-size: 21px;
            color: black;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 21px;
                height: 150%;
                left: 0;
                bottom: 0;
                transform: translateX(-25px) rotate(16deg);
                transform-origin: center;
                background: #949fb1;
                opacity: 0;
                z-index: -1;
                transition: transform .3s ease, height .3s ease-in-out;
            }

            &:hover:before {
                opacity: 1;
                transform: translateX(-11px) rotate(16deg);
            }
        }

        &-open &_nav {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

@mixin sidebar;
