@import '../variables/color.css';
@define-mixin footer $block: .footer {
    $(block) {
        padding-top: 80px;
        padding-bottom: 32px;
        text-align: center;
        background: var(--primaryBgColor);
        position: relative;
        overflow: hidden;
        color: black;
        font-size: 18px;
        font-weight: 400;
    }
}

@mixin footer;
